import styled from "styled-components"
import { Tokens } from "../components/Theme/Tokens"


export const Styles = styled.div`
  padding: calc(${Tokens.rhythm} * 4) 0 calc(${Tokens.rhythm} * 2);
  font-size: ${Tokens.font.size.large};
  text-align: center;

  p {
    line-height: 2;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`

export const ButtonStyles = styled.div`
  padding-bottom: calc(${Tokens.rhythm} * 4);
  width: 260px;
  margin: auto;
  text-align: center;
  cursor: pointer;

  button {
    padding: calc(${Tokens.rhythm} * 1) calc(${Tokens.rhythm} * 2);
    font-size: ${Tokens.font.size.large};
    font-weight: ${Tokens.font.weight.normal};
    border-radius: 50px;
  }
`

export const Styles2 = styled.div`
  padding: calc(${Tokens.rhythm} * 4) 0;
  font-size: ${Tokens.font.size.large};
  text-align: center;

  p {
    line-height: 2;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`

export const Styles3 = styled.div`
  padding: calc(${Tokens.rhythm} * 4) 0 calc(${Tokens.rhythm} * 2);
  font-size: ${Tokens.font.size.large};
  text-align: center;

  p {
    line-height: 1.5;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`

export const Styles4 = styled.div`
  padding: calc(${Tokens.rhythm} * 4) 0 calc(${Tokens.rhythm} * 2);
  font-size: ${Tokens.font.size.large};
  text-align: left;
  font-weight: bold;

  p {
    line-height: 1.5;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`
export const Styles5 = styled.div`
  font-size: ${Tokens.font.size.large};
  text-align: left;
  font-weight: bold;

  p {
    line-height: 1.5;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`

export const Styles6 = styled.div`
  padding: calc(${Tokens.rhythm} * 2) 0 calc(${Tokens.rhythm} * 2);
  font-size: ${Tokens.font.size.large};
  text-align: left;
  border-top: solid 1px black;

  p {
    line-height: 1.5;
  }

  span {
    line-height: 2;
    display: inline-block;
    vertical-align: middle;
  }
`

import React from "react"
import { graphql } from "gatsby"
import { Hero, Layout, Seo, Theme } from "../components"
import { Styles4, Styles5, Styles6 } from "./styles"


const JobsTemplate = ({ data, pageContext }) => {

  const { jobsFields } = data.page
  const hero = jobsFields.pageFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const openings = jobsFields?.openingFields?.filter(
    variant => variant.languageValue.slug === pageContext.lang
  )
  const langHeading =
    pageContext.lang === "pt_br" ? "Junte-se ao time!" : "Join the team!"


  return (
    <Layout>
      <Seo title={langHeading} />
      <Hero
        data={{
          title: hero.heading,
          subtitle: hero.subheading,
          image: hero.heroImage,
        }}
        desktop="400px"
        mobile="300px"
      />

      <Theme.Wrap size="narrow">
        <Styles4 dangerouslySetInnerHTML={{ __html: hero.copy }} />
      </Theme.Wrap>

      {openings?.map(opening => {
      return(<Theme.Wrap size="narrow">
          <Styles5 dangerouslySetInnerHTML={{ __html: opening.openingHeading }} />
          <Styles6 dangerouslySetInnerHTML={{ __html: opening.openingCopy }} />
        </Theme.Wrap>)}
    	)}
    </Layout>
  )
}

export const Query = graphql`query JobsTemplate {
  page: wpPage(slug: {eq: "jobs"}) {
    id
    jobsFields {
      pageFields {
        languageValue {
          slug
          name
        }
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
        heading
        subheading
        copy
      }
      openingFields {
        languageValue {
          slug
          name
        }
        openingHeading
        openingCopy
      }
    }
  }
}
`

export default JobsTemplate
